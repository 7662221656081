<template lang="pug">
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6 {{ $t('tech.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ updatedDate }}

		p.mb-4(v-html="$t('tech.desc')")
		br
		br
		pw-btn-up
</template>

<script>
import PwBtnUp from '@/components/Buttons/PwBtnUp';
import moment from 'moment';
import { bus } from '@/main';

export default {
    metaInfo() {
        return { title: bus.$t('tech.title') };
    },
    components: {
        PwBtnUp,
    },
    data() {
        let date = '2022-06-21';
        let day = moment(date).format('DD');

        return {
            updatedDate: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
        };
    },
    computed: {},
    created() {
        window.scrollTo(0, 0);

        this.viewGeoIpRussia();
    },
};
</script>
